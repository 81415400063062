import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Giveaways from '../../components/Page/Giveaways';
import SEO from '../../components/Seo';
import streamerPicture from './images/flavor.png';

const GiveawayPage = ({ pageContext: { intl } }) => {
  const lang = 'en';
  const title = 'Flavor Giveaway!';
  const description =
    'Finish simple tasks and increase your chance to get a crazy AK-47 CS:GO skin!';

  const hero = {
    title,
    description,
    glemUrl: 'https://gleam.io/gA9cj/flavor-x-skinwallet-giveaway',
    glemLoadingText: 'Loading...',
    glemPlaceholderText: 'Flavor x Skinwallet Giveaway',
  };
  const streamer = {
    title: 'Win a crazy AK-47 | Bloodsports from',
    description:
      'Turkish streamer and a great CS:GO player has something for you - AK-47 | Bloodsports from Skinwallet for grabs. Watch Flavor’s stream, finish tasks and gather entries to win this crazy AK-47 skin.',
    image: (
      <LazyLoadImage
        src={streamerPicture}
        alt="Flavor"
        width={300}
        height={300}
      />
    ),
  };
  const marketURL =
    'https://www.skinwallet.com/market/?ref=flavorflavor&utm_source=partner&utm_campaign=flavorflavor&utm_medium=flavorflavor&utm_content=giveaway';

  return (
    <>
      <SEO
        lang={lang}
        title={title}
        description={description}
        pathname={intl.originalPath}
        robots="noindex, follow"
      />
      <Giveaways
        lang={lang}
        hero={hero}
        streamer={streamer}
        marketURL={marketURL}
      />
    </>
  );
};

GiveawayPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default GiveawayPage;
